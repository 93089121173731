import { render, staticRenderFns } from "./OfferEdit.vue?vue&type=template&id=664b4466&scoped=true&"
import script from "./OfferEdit.vue?vue&type=script&lang=ts&"
export * from "./OfferEdit.vue?vue&type=script&lang=ts&"
import style0 from "./OfferEdit.vue?vue&type=style&index=0&id=664b4466&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "664b4466",
  null
  
)

export default component.exports